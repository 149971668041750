import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminGuard = ({ component: Component, ...rest }) => {

    function hasJWT() {
        if (localStorage.getItem("admin")) {
            const expiration = new Date(JSON.parse(atob(localStorage.getItem("admin").split('.')[1])).exp);
            const now = new Date();
            if (new Date(expiration.getTime() * 1000) < new Date(now.getTime())) {
                localStorage.removeItem("admin");
                return false
            } else {
                return true
            }
        } else {
            localStorage.removeItem("admin");
            return false
        }
    }

    return (
        <Route {...rest}
            render={props => (
                hasJWT() ?
                    <Component {...props} />
                    :
                    <Redirect to={{ pathname: '/backoffice-area/login' }} />
            )}
        />
    );
};

export default AdminGuard;
