import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service.js";
import { withRouter } from "react-router-dom";
import profile from "../../services/models/user/profile.js";
import Sidebar from "../sidebar/sidebar.js";
import { slide as Menu } from 'react-burger-menu'
import './stylee.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      email: '',
      StatsMenu: false,
      loading: true,
    };


  }

  logout = (e) => {
    e.preventDefault();
    AuthService.logout()
    // this.props.history.push("/login")
    window.location.reload(false);
  }

  profile(e) {
    e.preventDefault();
    this.props.history.push("/profile");
  }

  // toggleOffcanvas() {
  //   document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  // }
  // toggleRightSidebar() {
  //   document.querySelector(".right-sidebar").classList.toggle("open");
  // }

  updateProfile = () => {
    profile.me().then(res => {
      this.setState({
        fullname: res.data.me.fullname,
        username: res.data.me.username,
        email: res.data.me.email,
        image: process.env.REACT_APP_BASE_URL_ASSET + '/asset/images/' + res.data.me.image,
        wallet: res.data.me.wallet,
        loading: false

      }, () => {
        localStorage.setItem('image', res.data.me.image)
      })
    }).catch(err => {
      // console.log(err)
    })
  }

  componentDidMount = () => {
    this.updateProfile()
  }

  updatePassword = (e) => {
    console.log("update password")
    this.props.history.push("/password");
  }


  // componentDidUpdate(){
  //    this.updateProfile()
  // }



  render() {
    return (

      <>
        {/* <Menu isOpen={this.state.StatsMenu} onClose={() => this.setState({
          StatsMenu: false
        })}>

          <div className="card" >
            <div className="card-body">
              <div className="row">


                <div className="col-2 align-self-center">

                  {this.state.loading ? <Skeleton baseColor="#5d2cb4"
                    highlightColor="#7e4ad8"
                    borderRadius="1rem" /> : <img className="img-xs rounded-circle mr-2 text-center align-middle" 
                    src={ process.env.REACT_APP_BASE_URL_ASSET + '/asset/images/' +localStorage.getItem('image')} alt="profile" />}
                </div>
                <div className="col-8">
                  <small className="font-weight-bold"> {this.state.loading ? <Skeleton baseColor="#5d2cb4"
                    highlightColor="#7e4ad8"
                    borderRadius="1rem" /> : this.state.username}</small><br></br>
                  <small> {this.state.loading ? <Skeleton baseColor="#5d2cb4"
                    highlightColor="#7e4ad8"
                    borderRadius="1rem" /> : this.state.email}</small>
                </div>
              </div>
            </div>
          </div>



          <Link className="nav-link" to="/dashboard" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon pr-3">
              <i className="mdi mdi-chart-bar"></i>
            </span>
            <span className="menu-title">Dashboard</span>
          </Link>

          <Link className="nav-link" to="/screening" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon pr-3">
              <i className="mdi mdi-content-duplicate"></i>
            </span>
            <span className="menu-title">Screening Liquidity</span>
          </Link>


          <Link className="nav-link" to="/package" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon pr-3">
              <i className="mdi mdi-cart"></i>
            </span>
            <span className="menu-title">Bot Packages</span>
          </Link>

          <Link className="nav-link" to="/setup" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon pr-3">
              <i className="mdi mdi-clipboard-text"></i>
            </span>
            <span className="menu-title">Bot Setup</span>
          </Link>

          <Link className="nav-link" to="/transaction/dex-trade" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon pr-3">
              <i className="mdi mdi-chart-line
"></i>
            </span>
            <span className="menu-title">DEX Trade History</span>
          </Link>


          <Link className="nav-link" to="/transaction/referral" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon pr-3">
              <i className="mdi mdi-monitor-multiple
"></i>
            </span>
            <span className="menu-title">Bonus Report  </span>
          </Link>



          <Link className="nav-link" to="/transaction/history" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon pr-3">
              <i className="mdi mdi-file-multiple
"></i>
            </span>
            <span className="menu-title">Transaction   </span>
          </Link>



          <Link className="nav-link" to="/profile" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
            <span className="menu-icon  pr-3">
              <i className="mdi mdi-account-card-details
"></i>
            </span>
            <span className="menu-title">Profile</span>
          </Link>

        </Menu> */}

        <nav className="navbar-custom  navbar-expand-lg navbar-light bg-light fixed-top">




          <div className="d-flex justify-content-between">
            <div>



              <div className="d-flex">





                <div className="pl-2">
                  <Link to={"/dashboard"} className="sidebar-brand brand-logo sidebar-brand-wrapper">
                    <img src={require("../../assets/images/logo-lg.png")} alt="logo" style={{ width: "8rem" }} />
                  </Link>
                </div>


              </div>


            </div>



            <Dropdown alignRight as="div" className="nav-item ">
              <Dropdown.Toggle as="span" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  <button className=" btn btn-md align-middle position-static sidebar-brand brand-logo sidebar-brand-wrapper menu-item-small" style={{
                    background: "linear-gradient(45deg, rgba(93,44,180,1) 0%, rgba(230,37,234,1) 100%)",
                    width: "100%",
                    height: "100%",
                    borderColor: "transparent !important"
                  }} onClick={(e) => { this.setState({ StatsMenu: true }) }} >
                    <i className="mdi mdi-view-dashboard icon-md text-white"></i>
                  </button>

                  {/* <div className="d-flex">
                    {this.state.loading ? <Skeleton
                      circle
                      height="100%"
                      containerClassName="rounded-circle"
                    /> : <img className="img-xs rounded-circle mr-2 align-middle" src={ process.env.REACT_APP_BASE_URL_ASSET + '/asset/images/' +localStorage.getItem('image')} alt="profile" />}




                    <div className="d-none d-sm-block">
                      <small className="align-top ">Welcome <br></br></small>
                      <span className="font-weight-bold ">

                        {this.state.loading ? <Skeleton /> : this.state.username}
                      </span>

                    </div>
                  </div> */}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">

                <div className="text-center align-middle">
                  <Link to={"/profile"} className="sidebar-brand brand-logo">
                    {" "}
                    <img src={process.env.REACT_APP_BASE_URL_ASSET + '/asset/images/' + localStorage.getItem('image')} alt="profilelogo" className="rounded-circle mr-5 mb-2 ml-5 mt-2 align-middle"
                      style={{ maxWidth: "4rem" }} />
                    <span className="font-weight-bold text-dark">{this.state.username}</span>
                  </Link>
                </div>

                <Dropdown.Divider />

                <Link className="nav-link text-dark" to="/profile" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
                  <span className="menu-icon pr-3">
                    <i className="mdi mdi-account-card-details"></i>
                  </span>
                  <span className="menu-title">Profile</span>
                </Link>
                <Dropdown.Divider />


                <Link className="nav-link text-dark" to="/wallet" onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
                  <span className="menu-icon pr-3">
                    <i className="mdi mdi-wallet"></i>
                  </span>
                  <span className="menu-title">My Wallet</span>
                </Link>
                <Dropdown.Divider />

                {/* update password */}


                <a href="!#" className="nav-link text-dark" onClick={(e) => {
                  e.preventDefault();
                 

                  if (window.ethereum) {
                    window.ethereum
                      .request({ method: 'eth_requestAccounts' })
                      .then(res => {
                        this.setState({
                          walletAddressNow: res[0]
                        }, () => {
                          if (this.state.wallet.toLowerCase() === this.state.walletAddressNow.toLowerCase()) {

                            this.updatePassword(e)
                          } else {
                            alert("Wallet Address Not Match")
                          }
                        })
                      })

                  } else {
                    alert('please use browser Dapp or installed Crypto wallet')
                  }

                }}><span className="menu-icon pr-3">
                    <i className="mdi mdi-security"></i>
                  </span>
                  <span className="menu-title">Password</span>
                </a>



                <Dropdown.Divider />
                <Link className="nav-link text-dark"  to="/transaction/referral"  onClick={(e) => { this.setState({ StatsMenu: !this.state.StatsMenu }) }}>
                  <span className="menu-icon pr-3">
                    <i className="mdi mdi-monitor-multiple"></i>
                  </span>
                  <span className="menu-title">Bonus Report</span>
                </Link>




                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={this.logout.bind(this)} className="preview-item">

                  <div className="row">
                    <div className="col-md-8">
                      <span>Logout</span>
                    </div>
                    <div className="col-md-4">
                      <span className="text-danger"><i className="mdi mdi-logout-variant"></i></span>

                    </div>
                  </div>

                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>


          </div>
        </nav>

        {/* // <nav className="navbar p-0 fixed-top d-flex flex-row justify-content-between">


      //   <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
      //     <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-sm.png')} alt="logo" /></Link>
      //   </div>


      //   <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center text-white" type="button" onClick={this.toggleOffcanvas} >
      //     <span className="mdi mdi-format-line-spacing"></span>
      //   </button>

      //   <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch justify-content-between ">
      //     <div>
      //       <button className="navbar-toggler align-self-center text-white" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
      //         <span className="mdi mdi-menu"></span>
      //       </button>

      //     </div>

      //     <div className="align-item-stretch">
      //       <ul className="navbar-nav navbar-nav-right align-self-center">


      //         <Dropdown alignRight as="li" className="nav-item ">
      //           <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
      //             <div className="navbar-profile">
      //               <img className="img-xs rounded-circle" src={this.state.image} alt="profile" />
      //               <p className="mb-0 d-none d-sm-block navbar-profile-name">{this.state.fullname}</p>
      //               <i className="mdi mdi-menu-down d-none d-sm-block"></i>
      //             </div>
      //           </Dropdown.Toggle>

      //           <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
      //             <h6 className="p-3 mb-0">Profile</h6>
      //             <Dropdown.Divider />
      //             <Dropdown.Item href="!#" onClick={this.profile.bind(this)} className="preview-item">
      //               <div className="preview-thumbnail">
      //                 <div className="preview-icon bg-dark rounded-circle">
      //                   <i className="mdi mdi-settings text-success"></i>
      //                 </div>
      //               </div>
      //               <div className="preview-item-content">
      //                 <p className="preview-subject mb-1">Settings</p>
      //               </div>
      //             </Dropdown.Item>
      //             <Dropdown.Divider />
      //             <Dropdown.Item href="!#" onClick={this.logout.bind(this)} className="preview-item">
      //               <div className="preview-thumbnail">
      //                 <div className="preview-icon bg-dark rounded-circle">
      //                   <i className="mdi mdi-logout text-danger"></i>
      //                 </div>
      //               </div>
      //               <div className="preview-item-content">
      //                 <p className="preview-subject mb-1">Log Out</p>
      //               </div>
      //             </Dropdown.Item>
      //             <Dropdown.Divider />
      //             <p className="p-3 mb-0 text-center">Advanced settings</p>
      //           </Dropdown.Menu>
      //         </Dropdown>
      //       </ul>
      //     </div>





      //   </div>







      // </nav> */}
      </>

    );
  }
}

export default withRouter(Navbar);
