import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import exchangerService from '../services/models/exchanger/exchanger.service';

const ExchangerGuard = ({ component: Component, ...rest }) => {

    function hasJWT() {
        if (localStorage.getItem("exchanger")) {
            const expiration = new Date(JSON.parse(atob(localStorage.getItem("exchanger").split('.')[1])).exp);
            const now = new Date();
            exchangerService.validToken().then((res) => {
            console.log(res)
            }).catch((err) => {
                localStorage.removeItem("exchanger");
                return false
            })

             if (new Date(expiration.getTime() * 1000) < new Date(now.getTime())) {
                    localStorage.removeItem("exchanger");
                    return false
                } else {
                    return true
                }



        } else {
            localStorage.removeItem("wagmi.injected.shimDisconnect");
            localStorage.removeItem("wagmi.connected");
            localStorage.removeItem("wagmi.store");
            localStorage.removeItem("W3M_VERSION");
            localStorage.removeItem("user");
            return false
        }
    }

    return (
        <Route {...rest}
            render={props => (
                hasJWT() ?
                    <Component {...props} />
                    :
                    <Redirect to={{ pathname: '/exchanger/login' }} />
            )}
        />
    );
};

export default ExchangerGuard;
