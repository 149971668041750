import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { Col, Collapse, Dropdown } from 'react-bootstrap';
import { Collapse } from "react-bootstrap";
import profileModel from "../../services/models/admin/profileModel";

class sidebarExchange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: 'admin'
        }
    }


    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach((i) => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }


    onRouteChanged() {


        document.querySelector("#sidebar").classList.remove("active");
        // Object.keys(this.state).forEach((i) => {
        //     this.setState({ [i]: false });
        // });

        // const dropdownPaths = [
        //     { path: "/transaction", state: "allTranscationMenuOpen" },
        // ];

        // dropdownPaths.forEach((obj) => {
        //     console.log(obj)

        //     if (this.isPathActive(obj.path)) {
        //         this.setState({ [obj.state]: true });
        //     }
        // });
    }


    componentDidMount = () => {

    }


    render() {

        return (


            <nav
                className="sidebar sidebar-offcanvas"
                id="sidebar"
                style={{ height: "100vh", overflow: "hidden scroll" }}
            >
                <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <Link to={"/exchanger/network"} className="sidebar-brand brand-logo">
                        {" "}
                        <img src={require("../../assets/images/logo.png")} alt="logo" />
                    </Link>
                    <Link to={"/exchanger/network"} className="sidebar-brand brand-logo-mini">
                        {" "}
                        <img src={require("../../assets/images/icon.png")} alt="logo" />
                    </Link>
                </div>
                <ul className="nav">
                    <li className="nav-item nav-category">
                        <span className="nav-link">Main Menu</span>
                    </li>

                    <li
                        className={
                            this.isPathActive("/exchanger/wallet")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/exchanger/wallet">
                            <span className="menu-icon">
                                <i className="mdi mdi-wallet"></i>
                            </span>
                            <span className="menu-title">Wallet Deposit</span>
                        </Link>
                    </li>


                    <li
                        className={
                            this.isPathActive("/exchanger/network")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/exchanger/network">
                            <span className="menu-icon">
                                <i className="mdi mdi-account-network"></i>
                            </span>
                            <span className="menu-title">Network</span>
                        </Link>
                    </li>



                    <li
                        className={
                            this.isPathActive("/exchanger/transfer/balance")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/exchanger/transfer/balance">
                            <span className="menu-icon">
                                <i className="mdi mdi-swap-horizontal"></i>
                            </span>
                            <span className="menu-title">Deposit </span>
                        </Link>
                    </li>



                    {/* <li
                        className={
                            this.isPathActive("/exchanger/voucher")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/exchanger/voucher">
                            <span className="menu-icon">
                                <i className="mdi mdi-ticket"></i>
                            </span>
                            <span className="menu-title"> Voucher Code</span>
                        </Link>
                    </li> */}

                    <li
                        className={
                            this.isPathActive("/exchanger/withdrawal")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/exchanger/withdrawal">
                            <span className="menu-icon">
                                <i className="mdi mdi-wallet-membership"></i>
                            </span>
                            <span className="menu-title"> Withdrawal </span>
                        </Link>
                    </li>
                </ul>
            </nav>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }

    componentDidMount() {
        this.onRouteChanged();
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector("body");
        document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
            el.addEventListener("mouseover", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.add("hover-open");
                }
            });
            el.addEventListener("mouseout", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.remove("hover-open");
                }
            });
        });
    }
}

export default withRouter(sidebarExchange);
