import React from "react";
import { ReactComponent as BNB } from '../../assets/SVG/bnb-01.svg'
import { ReactComponent as CHECK } from '../../assets/SVG/checkin-01.svg'
import { ReactComponent as MARKETING } from '../../assets/SVG/marketing-01.svg'
import { ReactComponent as LEADER } from '../../assets/SVG/leader-01.svg'
import { ReactComponent as MANAGER } from '../../assets/SVG/manager-01.svg'
import { ReactComponent as CHANGEINFO } from '../../assets/SVG/change information or add.svg'
import { ReactComponent as CHANGEPASS } from '../../assets/SVG/change password-01.svg'
import { ReactComponent as CHANGETRANSPASS } from '../../assets/SVG/change transaction password-01.svg'
import { ReactComponent as REEDEMVOUCHER } from '../../assets/SVG/voucher-01.svg'
import { ReactComponent as VOUCHERADMIN } from '../../assets/SVG/admin-01.svg'
import { ReactComponent as VOUCHERAGEN } from '../../assets/SVG/agen2-01.svg'
import { ReactComponent as AVAILABLE } from '../../assets/SVG/check-01.svg'
import { ReactComponent as BONUSGACHA } from '../../assets/SVG/gacha-01.svg'
import { ReactComponent as BONUSREFERRAL } from '../../assets/SVG/referral-01.svg'
import { ReactComponent as TOTALBONUSDAILY } from '../../assets/SVG/bonus-01.svg'
import { ReactComponent as ROOM } from '../../assets/SVG/room-01.svg'
import { ReactComponent as ORMEMBERSHIP } from '../../assets/SVG/or-membership-01.svg'
import { ReactComponent as ORGEMS } from '../../assets/SVG/bonus or gems-01.svg'
import { ReactComponent as DAILYTASK } from '../../assets/SVG/task-01.svg'
import { ReactComponent as BONUSSPIN } from '../../assets/SVG/spin-01.svg'
import { ReactComponent as DEPOSIT } from '../../assets/SVG/total deposit dtc-01.svg'
import { ReactComponent as DEPOSITSTATISTIC } from '../../assets/SVG/deposit-01.svg'
import { ReactComponent as PGPFEE } from '../../assets/SVG/fee-dtc-01.svg'
import { ReactComponent as PREMIUMVOUCHER } from '../../assets/SVG/premium-voucher-01.svg'
import { ReactComponent as VIPVOUCHER } from '../../assets/SVG/vvip-voucher-01.svg'
import { ReactComponent as FEEWDVEEGA } from '../../assets/SVG/withdraw-01.svg'
import { ReactComponent as WITHDRAWVEEGA } from '../../assets/SVG/wd-veega-01.svg'
import { ReactComponent as PGPWITHDRAWAL } from '../../assets/SVG/fee withdraw-01.svg'
import { ReactComponent as TOTALFEEWD } from '../../assets/SVG/fee-dtc-01.svg'
import { ReactComponent as TOTALBONUSPGP } from '../../assets/SVG/bonus-01.svg'
import { ReactComponent as SWAPDORA } from '../../assets/SVG/swap dorayaki-01.svg'
import { ReactComponent as BONUSMEMBERSTATIS } from '../../assets/SVG/or membership-01.svg'
import { ReactComponent as GEMSSWAPTOLIVE } from '../../assets/SVG/lives-01.svg'
import { ReactComponent as GEMSSWAPTONOBITA } from '../../assets/SVG/nobita-01.svg'
import { ReactComponent as FEEWDVEEGASTATIC } from '../../assets/SVG/withdraw-01.svg'
import { ReactComponent as CLAIMVEEGASTATIC } from '../../assets/SVG/claim-01.svg'
import { ReactComponent as VOUCHERAVAISTATIC } from '../../assets/SVG/vvip-voucher-01.svg'
import { ReactComponent as DAILYTASKSTATIS } from '../../assets/SVG/task-01.svg'
import { ReactComponent as WINROOMSTATIC } from '../../assets/SVG/win-01.svg'
import { ReactComponent as ROYALTY } from '../../assets/SVG/royalti-01.svg'
import { ReactComponent as MENUHOME } from '../../assets/SVG/d.svg'
import { ReactComponent as MENUSHOP } from '../../assets/SVG/c.svg'
import { ReactComponent as MENUBALANCE } from '../../assets/SVG/b.svg'
import { ReactComponent as MENUDEXTRADE } from '../../assets/SVG/a.svg'
import { ReactComponent as MENUACCOUNT } from '../../assets/SVG/e.svg'
import { ReactComponent as MENUPROFILEINFORMATION } from '../../assets/SVG/f.svg'
import { ReactComponent as MENUUPDATEPROFILE } from '../../assets/SVG/g.svg'
import { ReactComponent as MENUCHANGEPASSWORD } from '../../assets/SVG/h.svg'
import { ReactComponent as MENUHISTORYBONUS } from '../../assets/SVG/i.svg'
import { ReactComponent as MENULOGOUT } from '../../assets/SVG/j.svg'
import { ReactComponent as BACK } from '../../assets/SVG/back.svg'
import { ReactComponent as FRAMEUPDATE } from '../../assets/SVG/frameUpdate.svg'
import { ReactComponent as EDITBUTTON } from '../../assets/SVG/editButton.svg'
import { ReactComponent as SECURE } from '../../assets/SVG/secure.svg'
import { ReactComponent as DEPOSITONE } from '../../assets/SVG/deposit.svg'
import { ReactComponent as WITHDRAWONE } from '../../assets/SVG/withdraw.svg'
import { ReactComponent as ASSETONE } from '../../assets/SVG/asst.svg'



export const DepositBalance = () => {
    return <DEPOSITONE width='100%' />
}

export const WithdrawOne = () => {
    return <WITHDRAWONE width='100%' />
}


export const AssetOne = () => {
    return <ASSETONE width='100%' />
}


export const MenuHome = () => {
    return <MENUHOME width='100%' />
}
export const MenuShop = () => {
    return <MENUSHOP width='100%' />
}
export const MenuBalance = () => {
    return <MENUBALANCE width='100%' />
}
export const MenuDextrade = () => {
    return <MENUDEXTRADE width='100%' />
}
export const MenuAccount = () => {
    return <MENUACCOUNT width='100%' />
}

export const MenuProfileInformation = () => {
    return <MENUPROFILEINFORMATION width='100%' />
}

export const MenuUpdateProfile = () => {
    return <MENUUPDATEPROFILE width='100%' />
}

export const MenuChangePassword = () => {
    return <MENUCHANGEPASSWORD width='100%' />
}

export const MenuHistoryBonus = () => {
    return <MENUHISTORYBONUS width='100%' />
}

export const MenuLogout = () => {
    return <MENULOGOUT width='100%' />
}

export const Back = () => {
    return <BACK width='100%' />
}

export const FrameUpdate = () => {
    return <FRAMEUPDATE width='10rem' style={{ marginLeft: "auto", marginRight: "auto", display: "absolute" }} />
}

export const FrameDashboard = () => {
    return <FRAMEUPDATE width='6rem' style={{ marginLeft: "auto", marginRight: "auto", display: "absolute" }} />
}

export const EditButton = () => {
    return <EDITBUTTON width='1.5rem' style={{ marginTop: "60px", paddingLeft: "10px", cursor: "pointer" }} />
}

export const Secure = () => {
    return <SECURE width='16rem' />
}


export const Bnb = () => {
    return <BNB width='100%' />
}
export const Check = () => {
    return <CHECK fill='#f1734f' width='100%' />
}

export const Marketing = () => {
    return <MARKETING fill='#f1734f' width='80%' />
}
export const Leader = () => {
    return <LEADER fill='#f1734f' width='80%' />
}
export const Manager = () => {
    return <MANAGER fill='#f1734f' width='80%' />
}
export const ChangeInfo = () => {
    return <CHANGEINFO fill='#f1734f' width='80%' />
}
export const ChangePass = () => {
    return <CHANGEPASS fill='#f1734f' width='80%' />
}
export const ChangeTransPass = () => {
    return <CHANGETRANSPASS fill='#f1734f' width='80%' />
}
export const ReedemVoucher = () => {
    return <REEDEMVOUCHER fill='#f1734f' width='80%' />
}
export const VoucherAdmin = () => {
    return <VOUCHERADMIN fill='#fff' width='80%' />
}
export const VoucherAgen = () => {
    return <VOUCHERAGEN fill='#f1734f' width='80%' />
}
export const AvailableVoucher = () => {
    return <AVAILABLE fill='#f1734f' width='80%' />
}
export const BonusGacha = () => {
    return <BONUSGACHA fill='#f1734f' width='80%' />
}
export const BonusReferral = () => {
    return <BONUSREFERRAL fill='#f1734f' width='80%' />
}
export const TotalBonusDaily = () => {
    return <TOTALBONUSDAILY fill='#f1734f' width='80%' />
}
export const Room = () => {
    return <ROOM fill='#f1734f' width='80%' />
}
export const OrMembership = () => {
    return <ORMEMBERSHIP fill='#f1734f' width='100%' />
}
export const OrGems = () => {
    return <ORGEMS fill='#f1734f' width='80%' />
}
export const GemsPremium = () => {
    return <ORGEMS fill='#fff' width='80%' />
}
export const DailyTask = () => {
    return <DAILYTASK fill='#fff' width='80%' />
}
export const BonusSpin = () => {
    return <BONUSSPIN fill='#f1734f' width='80%' />
}
export const Deposit = () => {
    return <DEPOSIT fill='#f1734f' width='80%' />
}
export const PGPAdmin = () => {
    return <VOUCHERADMIN fill='#f1734f' width='80%' />
}
export const PGPAgen = () => {
    return <VOUCHERAGEN fill='#fff' width='80%' />
}
export const PGPFee = () => {
    return <PGPFEE fill='#fff' width='80%' />
}
export const TotalWdPgp = () => {
    return <TOTALFEEWD fill='#fff' width='80%' />
}
export const FeeWdVeega = () => {
    return <FEEWDVEEGA fill='#fff' width='80%' />
}
export const PremiumVoucher = () => {
    return <PREMIUMVOUCHER fill='#fff' width='80%' />
}
export const VIPVoucher = () => {
    return <VIPVOUCHER fill='#fff' width='80%' />
}
export const PgpWD = () => {
    return <PGPWITHDRAWAL fill='#fff' width='80%' />
}
export const WDVeega = () => {
    return <WITHDRAWVEEGA fill='#fff' width='80%' />
}
export const FeeWd = () => {
    return <FEEWDVEEGA fill='#fff' width='80%' />
}
// Data Icon Statistic
export const DepositStatistic = () => {
    return <DEPOSITSTATISTIC fill='#9d4a5a' width='80%' />
}
export const PGPAdminStatis = () => {
    return <VOUCHERADMIN fill='#9d4a5a' width='80%' />
}
export const PGPAgenStatis = () => {
    return <VOUCHERAGEN fill='#9d4a5a' width='80%' />
}
export const TotalBonusPGP = () => {
    return <TOTALBONUSPGP fill='#5465cd' width='80%' />
}
export const DailyCheckin = () => {
    return <CHECK fill='#5465cd' width='80%' />
}
export const SwapDora = () => {
    return <SWAPDORA fill='#5465cd' width='60%' />
}
export const GemsStatis = () => {
    return <ORGEMS fill='#5465cd' width='80%' />
}
export const RoomStatis = () => {
    return <ROOM fill='#5465cd' width='80%' />
}
export const BonusMemberStatis = () => {
    return <BONUSMEMBERSTATIS fill='#5465cd' width='80%' />
}
export const GemsSwapToLive = () => {
    return <GEMSSWAPTOLIVE fill='#5465cd' width='80%' />
}
export const GemsSwapToNobita = () => {
    return <GEMSSWAPTONOBITA fill='#5465cd' width='80%' />
}
export const WDVeegaStatic = () => {
    return <FEEWDVEEGASTATIC fill='#5465cd' width='80%' />
}
export const ClaimVeegaStatic = () => {
    return <CLAIMVEEGASTATIC fill='#5465cd' width='80%' />
}
export const PgpWDStatic = () => {
    return <WITHDRAWVEEGA fill='#fff' width='80%' />
}
export const VoucherAvaiStatic = () => {
    return <VOUCHERAVAISTATIC fill='#f1734f' width='80%' />
}
export const DailyTaskStatis = () => {
    return <DAILYTASKSTATIS fill='#f1734f' width='80%' />
}
export const CheckStatic = () => {
    return <CHECK fill='#fff' width='80%' />
}
export const WinRoomStatic = () => {
    return <WINROOMSTATIC fill='#fff' width='80%' />
}
export const RoyaltiStatis = () => {
    return <ROYALTY fill='#fff' width='80%' />
}
export const Nobita = () => {
    return <GEMSSWAPTONOBITA fill='#f1734f' width='80%' />
}