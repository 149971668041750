import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service.js";
import { withRouter } from "react-router-dom";
import profile from "../../services/models/user/profile.js";
import profileModel from "../../services/models/admin/profileModel.js";

class NavbarAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: ''
        };

    }


    componentDidMount = () => {
        profileModel.me().then(res => {
            this.setState({
                username: res.data.position
            })
        })
    }




    logout = (e) => {
        e.preventDefault();
        AuthService.logout()
        window.location.reload(false);
    }

    profile(e) {
        e.preventDefault();
        this.props.history.push("/backoffice-area/profile");
    }

    toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }
    toggleRightSidebar() {
        document.querySelector(".right-sidebar").classList.toggle("open");
    }



    render() {
        return (

            <nav className="navbar p-0 fixed-top d-flex flex-row justify-content-between">
                <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo-mini" to="/backoffice-area/dashboard"><img src={require('../../assets/images/icon.png')} alt="logo" /></Link>
                </div>



                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center text-white" type="button" onClick={this.toggleOffcanvas} >
                    <span className="mdi mdi-format-line-spacing"></span>
                </button>

                <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch justify-content-between ">
                    <div>
                        <button className="navbar-toggler align-self-center text-white" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                            <span className="mdi mdi-menu"></span>
                        </button>


                    </div>

                    <div className="align-item-stretch">
                        <ul className="navbar-nav navbar-nav-right align-self-center">

                            <span className="navbar-nav navbar-nav-right align-self-center pt-3">{this.state.username}</span>

                            <Dropdown alignRight as="li" className="nav-item ">
                                <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                                    <div className="navbar-profile">
                                        {/* <img className="img-xs rounded-circle" src={this.state.image} alt="profile" /> */}
                                        <p className="mb-0 d-none d-sm-block navbar-profile-name">{this.state.fullname}</p>
                                        <i className="mdi mdi-menu-down Icon-lg   d-none d-sm-block pt-2 text-white"></i>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                                    <h6 className="p-3 mb-0">Profile</h6>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="!#" onClick={this.profile.bind(this)} className="preview-item">
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-dark rounded-circle">
                                                <i className="mdi mdi-settings text-success"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content">
                                            <p className="preview-subject mb-1">Settings</p>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="!#" onClick={this.logout.bind(this)} className="preview-item">
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-dark rounded-circle">
                                                <i className="mdi mdi-logout text-danger"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content">
                                            <p className="preview-subject mb-1">Log Out</p>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <p className="p-3 mb-0 text-center">Advanced settings</p>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>


                </div>

            </nav>
        );
    }
}

export default withRouter(NavbarAdmin);
