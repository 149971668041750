import Axios from "axios";
import authHeaderExchanger from "../../auth-header-exchanger";


const axios2 = Axios.create()


class authServiceExchanger {
    login(wallet) {
        return axios2.post(process.env.REACT_APP_BASE_URL + '/exchanger/login', {
            wallet,
        })
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem("exchanger", JSON.stringify(response.data.access_token));
                    return true;
                } else {
                    return false;
                }
            });
    }


    refresh() {
        return authHeaderExchanger().post(process.env.REACT_APP_BASE_URL + '/exchanger/refresh')
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem("exchanger", JSON.stringify(response.data.access_token));
                }
            }).catch(err => {
                localStorage.removeItem("exchanger");
                this.props.history.push('/dashboard')
            });
    }

    logout = () => {
        localStorage.removeItem("exchanger");
        return true;
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('exchanger'));;
    }
}

export default new authServiceExchanger();