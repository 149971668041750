import Axios from "axios";
import authHeader from "./auth-header";
import authHeaderAdmin from "./auth-header-admin";


const axios2 = Axios.create()


class AuthService {
  login(wallet) {
    return axios2.post(process.env.REACT_APP_BASE_URL + '/login', {
      wallet
    })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.access_token));
          return true;
        } else {
          return false;
        }
      });
  }


  loginDefault(username, password){
    return axios2.post(process.env.REACT_APP_BASE_URL + '/login/default', {
      username,password
    })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.access_token));
          return true;
        } else {
          return false;
        }
      });
  }


  refresh() {
    return authHeader().post(process.env.REACT_APP_BASE_URL + '/refresh')
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.access_token));
        }
      }).catch(err => {
        localStorage.removeItem("user");
        this.props.history.push('/dashboard')
      });
  }

    refreshAdmin() {
    return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/refresh')
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("admin", JSON.stringify(response.data.access_token));
        }
      }).catch(err => {
        localStorage.removeItem("admin");
        this.props.history.push('/backoffice-area/dashboard')
      });
  }

  logout = () => {
    localStorage.removeItem("account");
    localStorage.removeItem("wagmi.cache");
    localStorage.removeItem("wagmi.wallet");
    localStorage.removeItem("wagmi.injected.shimDisconnect");
    localStorage.removeItem("wagmi.connected");
    localStorage.removeItem("wagmi.store");
    localStorage.removeItem("W3M_VERSION");
    localStorage.removeItem("-walletlink:https://www.walletlink.org:session:id");
    localStorage.removeItem("-walletlink:https://www.walletlink.org:session:linked");
    localStorage.removeItem("-walletlink:https://www.walletlink.org:session:secret");
    localStorage.removeItem("-walletlink:https://www.walletlink.org:session:version");
    localStorage.removeItem("user");
    localStorage.removeItem("admin");
    return true;
  }

  register(sponsor, fullname, username, phone, email, wallet, password, confirm_password) {
    return axios2.post(process.env.REACT_APP_BASE_URL + "/signup", {
      sponsor,
      fullname,
      username,
      phone,
      email,
      wallet,
      password,
      confirm_password,
    }).then(response => {
      if (response.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data.access_token));
        return true;
      } else {
        return false;
      }
    });;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();